.modal {
  min-height: 400px;
  max-height: 100vh;
  min-width: 200px;
  text-align: center;
  padding: 40px 55px 55px 55px;
  -webkit-box-shadow: 0px 26px 41px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 0px 26px 41px 0px rgba(0, 0, 0, 0.34);
  box-shadow: 0px 26px 41px 0px rgba(0, 0, 0, 0.34);
  transform: translate(-50%, -40%) scale(0.9);
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -45%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: auto;
}

.modalAfterOpen {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.modalBeforeClose {
  transform: translate(-50%, -60%) scale(0.9);
  opacity: 0;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 1000;
}

.modalOverlayAfterOpen {
  opacity: 1;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalOverlayBeforeClose {
  opacity: 0;
}

.titleContainer {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  margin-top: -20px;
  display: flex;
  align-items: flex-start;
  justify-items: center;
  flex: 0 0 auto;
}

.title {
  flex: 1;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  padding-top: 25px;
}

.content h6 {
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  color: #0c171a;
  margin: 0 0 20px;
}

.illustration {
  width: 200px;
  margin: 0 auto 40px;
}

.ctaContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
  margin-top: auto;
  padding-top: 50px;
}

.altCTA {
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  color: #000000;
  font-weight: bold;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}

.altCTA:hover {
  background-color: rgb(244, 200, 143, 0.3);
}

@media (max-width: 600px) {
  .modal {
    height: 80vh;
    min-width: auto;
    width: calc(100% - 40px);
    padding: 10px 15px;
    margin-right: -50%;
  }
  .heading {
    padding-right: 30px;
    text-align: left;
  }
  .content {
    padding: 20px 0;
  }

  .titleContainer {
    margin-top: 40px;
    font-size: 1rem;
    line-height: 1.3125rem;
  }
  .title {
    font-size: 14px;
  }
  .ctaContainer {
    gap: 30px;
    margin-top: auto;
    padding: 10px 25px 30px 0px;
  }

  .altCTA {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}
