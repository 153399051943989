.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  padding: 24px 12px;
  border-radius: 4px;
  border: 1px solid var(--grey-2, #cfd3d6);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.header:hover {
  background: var(--grey-1, #ebedf0);
}

.header:active {
  background: var(--grey-2, #cfd3d6);
}

.headerOpen {
  border-radius: 4px 4px 0px 0px;
}

.title {
  display: flex;
  align-items: center;
  gap: 6px;
}

.caret {
  height: 10px;
  fill: #1d1f23;
}

.caretOpen {
  transform: rotate(180deg);
}

.body {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid var(--grey-2, #cfd3d6);
  border-top: 0px;
}

.bodyHidden {
  display: none;
}

.container table th:last-of-type,
.container table td:last-of-type {
  border-right: 0px;
}

.container table th:first-of-type,
.container table td:first-of-type {
  border-left: 0px;
  min-width: 105px;
}

.container table tbody tr:last-of-type td {
  border-bottom: 0px;
}
