.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 230px;
  left: 1166px;
  top: 239px;
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 1);
  cursor: pointer;
}

.container:hover {
  box-shadow: 0px 0px 0px 3px #af917080;
}

.selectedOption {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.selectedOption svg {
  width: 14px;
  height: 20px;
  margin-right: 30px;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.dropdownItems {
  top: 40px;
  position: absolute;
}

.dropdownItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 230px;
  left: 871px;
  top: 282px;
  border-radius: 5px;
  background-color: rgba(243, 243, 243, 1);
  cursor: pointer;
}

.dropdownItemBordered {
  border: 1px solid rgba(196, 196, 196, 1);
}

.optionLabel {
  padding-right: 10px;
  cursor: pointer;
}

.currentlySelected {
  background-color: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 0px;
}

.datePickerWrapper {
  height: 100%;
  text-align: center;
  line-height: 40px;
}

.datePickerPopper {
  width: 550px !important;
}

.datePickerCalendar {
  min-height: 300px !important;
}
