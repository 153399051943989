.topText {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #6f7273;
}

.assetValueText {
  font-weight: 500;
  font-size: 48px;
  line-height: 140%;
  color: var(--candor-black);
  justify-content: center;
}
