.modal {
  padding: 20px 24px 28px;
  min-height: auto;
  width: 420px;
}

.modalContent {
  padding: 0;
  min-height: auto;
}
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modalTitle {
  color: #202223;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 44px;
}

.modalDescription {
  color: #6b7075;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-bottom: 52px;
}

.buttonsContainer {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.buttonsContainer button {
  text-transform: none;
  font-weight: 500;
}
