.pageIndicatorsContainer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  gap: 4px;
}

.pageIndicator {
  width: 12px;
  height: 12px;
  background: var(--candor-grey-50);
  border-radius: 50%;
}

.active {
  background: #6f7273;
}
