.tabsContainer {
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.tab {
  display: inline-block;
  padding: 2px 24px 10px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  min-width: 125px;
  text-align: center;
}

.tab:hover,
.tab.active {
  border-bottom-color: var(--candor-black);
}

.tab.active {
  font-weight: 500;
}
