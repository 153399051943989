.navBar {
  width: 200px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--candor-grey-30);
  align-items: center;
  gap: 40px;
  background-color: white;
  position: fixed;
  height: 100%;
}

.logo {
  margin-top: 30px;
  margin-bottom: 120px;
}

.logo > svg {
  width: 100%;
  max-width: 80px;
  cursor: pointer;
  fill: black;
}

.logo > svg.sofiaLogo {
  max-width: 150px;
}

.navItem {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  position: relative;
  color: #aeaeae;
  padding-left: 18px;
  transition: all 0.3s ease;
}

.navItem svg {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  display: block;
  margin: 0 auto;
}

.navItem svg {
  fill: #aeaeae;
}

.navItem > span {
  flex: 1 1 auto;
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  padding-left: 15px;
}

.navItem.disabled {
  cursor: not-allowed;
  color: #c3c3c3;
}

.navItem.active {
  color: black;
}

.navItem.active svg {
  fill: black;
}

.navItem.active::after {
  content: "";
  position: absolute;
  left: -1px;
  height: 24px;
  width: 4px;
  border-right: 4px solid black;
  border-radius: 5px;
  margin-right: 1px;
}

.navItem:last-of-type {
  margin-bottom: 200px;
  margin-top: auto;
}

.navItem:hover {
  box-shadow: none;
}

.navItem:not(.disabled):hover {
  color: black;
}

.navItem:not(.disabled):hover svg {
  fill: black;
}

.burgerNav,
.mobileNav {
  display: none;
}

.hideTooltip {
  display: none;
}

@media (max-width: 1200px) {
  .navBar {
    width: 80px;
  }

  .logo {
    max-width: 50px;
    margin-bottom: 120px;
  }

  .hideTooltip:global(:not(.rc-tooltip-hidden)) {
    display: block;
  }

  .navItem {
    padding-left: 0;
  }

  .navItem svg {
    flex: 0 0 22px;
    width: 22px;
    height: 22px;
  }

  .navItem > span {
    display: none;
  }
}

.burgerWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.burgerWrapper > svg > * {
  stroke: black;
}

@media (max-width: 600px) {
  .navBar {
    display: none;
  }
  .navItem {
    padding: 10px 0px;
    color: #c4c4c4;
  }
  .navItem:last-of-type {
    margin: 0px;
  }
  .navItem > span {
    display: block;
    font-size: 16px;
    padding-left: 10px;
  }
  .navItem svg {
    margin: 0px;
    height: 16px;
    width: 16px;
  }
  .navItem.active::after {
    display: none;
  }

  .mobileNav {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 999;
  }

  .navBurgerWrap {
    flex: 1;
    justify-content: space-between;
    padding: 20px;
    display: flex;
    border-bottom: 1px solid var(--Grey-2, #cfd3d6);
    background: var(--Primary-White, #fff);
  }

  .navBurgerWrap svg > * {
    fill: black;
  }

  .mobileNavOpen {
    height: 100vh;
  }

  .mobileLogo {
    height: 20px;
    fill: white;
  }

  .burger {
    height: 20px;
  }

  .burgerNav {
    width: 50vw;
    right: 0;
    margin: 20px 15px;
    z-index: 999;
    padding: 25px;
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: #ffffff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    font-weight: bold;
    transition: all 0.3s ease;
  }
}
