.headerContainer {
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 20px;
}

.headerContainer.collapsed {
  border-bottom: 1px solid white;
  gap: 48px;
}

.tabTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 200px;
  border-radius: 0px;
  background-color: rgba(240, 240, 240, 1);
  opacity: 0.5;
  cursor: pointer;
}

.tabTitleContainer.collapsed {
  background-color: none;
  background: none;
  border-bottom: 4px solid white;
  width: fit-content;
}

.tabTitleContainer:hover {
  background-color: rgba(240, 240, 240, 0.5);
  opacity: 1;
}

.tabTitleContainer.collapsed:hover {
  border-bottom: 4px solid rgba(6, 6, 6, 0.5);
  opacity: 1;
  background-color: none;
  background: none;
}

.active {
  opacity: 1;
  background-color: rgba(56, 92, 84, 0.08);
  border-bottom: 4px solid rgba(6, 6, 6, 1);
}

.active.collapsed {
  background: none;
  border-bottom: 4px solid rgba(6, 6, 6, 1);
}

.tabTitleText {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
}

.tabTitleText.collapsed {
  font-weight: 400;
  font-size: 16px;
}

@media (max-width: 600px) {
  .tabTitleContainer {
    opacity: 1;
    background: #f6f6f6;
    color: #b2b2b2;
    width: fit-content;
    flex: 1;
  }
  .tabTitleContainer:hover {
    background-color: #f6f6f6;
  }
  .tabTitleContainer:first-of-type {
    margin-right: -1px;
  }
  .active {
    background: #e1e4e4;
    color: #000000;
  }
}
