.container {
  height: auto;
  min-height: 100vh;
}

.header,
.content {
  margin-left: 200px;
  padding: 40px 40px 0px 40px;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  flex: 1 1 420px;
  min-height: 100vh;
}

.containerNoNav {
  background-color: var(--candor-background-grey);
}

.rightContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 360px;
}

.rightTitle {
  display: flex;
  justify-content: flex-end;
}

.rightTitle button {
  z-index: 100;
}

.leftContent {
  position: relative;
  padding: 0;
  width: 100%;
}

.irsContent {
  padding: 0px;
  gap: 0px;
  height: 100vh;
  overflow: hidden;
  align-items: stretch;
}

.title {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 14px;
}

.onTrackPill {
  display: flex;
  align-items: center;
  padding: var(--Radius-XS, 2px) 8px;
  gap: 10px;
  border-radius: 4px;
  background: var(--Success-Green-1, #ebfaac);
  color: var(--Primary-Grey-9, #141719);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  margin-bottom: -2px;
}

.onTrackPill svg {
  width: 14px;
  height: auto;
}

/* Collapse nav bar on tablet */
@media (max-width: 1200px) {
  .header,
  .content {
    margin-left: 80px;
  }
}

/* Tablet */
@media (max-width: 1200px) {
  .leftContainer {
    flex: 1;
    width: 100%;
    min-height: auto;
    padding-bottom: 0px;
  }

  .leftContainerSolo > .leftContent {
    margin-top: -100px;
  }
  .leftContainerSolo {
    padding-bottom: 100px;
  }
}

/* Mobile */
@media (max-width: 600px) {
  .header {
    display: none;
  }

  .container {
    display: flex;
  }
  .container > :global(.display-enter) {
    opacity: 0;
  }

  .container > :global(.display-enter-active) {
    opacity: 1;
    transition: all 0.3s ease;
  }

  .container > :global(.display-exit-active) {
    opacity: 0;
    transition: all 0.3s ease;
  }

  .leftContainer {
    flex: 1;
    width: 100%;
    min-height: auto;
    padding-bottom: 0px;
    min-width: unset;
    margin-top: 60px;
  }
  .rightContainer {
    width: 100%;
    flex: 0 0 100%;
    max-width: initial;
    overflow: visible;
    padding-top: 0px;
    min-width: unset;
    margin-top: -24px;
    display: flex;
    flex-shrink: 1;
  }
  .content {
    margin-left: 0px;
    flex-direction: column;
    padding: 20px;
    flex-wrap: nowrap;
  }

  .leftContainerSolo > .leftContent {
    margin-top: -100px;
  }
  .leftContainerSolo {
    padding-bottom: 100px;
  }

  .irsContent {
    padding: 0;
    height: auto;
    overflow: auto;
  }
}
