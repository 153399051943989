.headContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.headContainerLeft {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.headContainerLeft div {
  display: inline-flex;
}

.headContainerRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerContainerMobile {
  display: none;
}

@media (max-width: 600px) {
  .headContainer {
    flex-direction: column;
  }
  .dateContainer {
    display: none;
  }
  .headerContainerMobile {
    display: flex;
  }
  .headContainerLeft {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .headContainerLeft > div {
    margin: 0;
    flex: 1;
    min-width: max-content;
  }
}
