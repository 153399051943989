.table {
  width: 100%;
  margin-bottom: 0px;
}

.table thead {
  color: var(--grey-5, #6b7075);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.table tbody {
  color: var(--primary-grey-9, #141719);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.table th,
.table td {
  border: 1px solid var(--candor-grey-30, #e6e8ea);
  padding: 8px;
  overflow-wrap: anywhere;
}

.table tbody tr:nth-of-type(odd) {
  background: #f8f9f9;
}
