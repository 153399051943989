.table {
  width: 100%;
  margin-bottom: 0px;
}

.table > thead {
  border-bottom: 1px solid var(--Grey-1, #e6e8ea);
  background: var(--Grey-0, #f8f9f9);
  color: var(--Primary-Grey-9, #141719);
}

.table th,
.table td {
  border-bottom: 1px solid var(--Grey-1, #e6e8ea);
  padding: 15px 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.loading {
  padding: 15px;
}
