.wrapper {
  background-color: #ffffff;
  margin-left: 200px;
  max-width: 1400px;
  padding: 0px 48px 150px;
}

.header {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userButton {
  margin-left: auto;
}

.title {
  font-size: 24px;
  font-weight: 500;
}

.linkBack {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--candor-grey-70);
  display: inline-flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
}

.linkBack:hover {
  color: var(--candor-dark-gold);
}

.linkBack > svg {
  height: 12px;
  transform: rotate(180deg);
  fill: var(--candor-grey-70);
}

.linkBack:hover > svg {
  fill: var(--candor-dark-gold);
}

.content {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  height: 100%;
}

/* Collapse nav bar on tablet */
@media (max-width: 1200px) {
  .wrapper {
    margin-left: 80px;
  }
}

/* Mobile */
@media (max-width: 600px) {
  .wrapper {
    margin-left: 0px;
    flex-direction: column;
    padding: 0px 0px 100px;
    min-height: unset;
  }

  .header {
    background-position-x: 50vw;
    padding-top: 80px;
    padding: 30px;
    padding-top: 70px;
    background-color: #000000;
  }

  .title {
    font-size: 22px;
    font-weight: bold;
    color: white;
    margin: 0;
  }

  .content {
    margin-top: -100px;
    padding: 30px 20px;
  }
}
