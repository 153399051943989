.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  min-height: 50vh;
}

.loadMoreButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
}

.loadMoreButtonContainer button {
  margin-bottom: 80px;
}

.noMoreResultsContainer {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 30px;
}

.noResultsContainer {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 250px;
}

.rowsContainer {
  flex: 1;
  width: 100%;
}

.loadingContainer {
  height: 40px;
  width: 40px;
  margin-left: 6px;
}

.loader,
.loader:after {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.loader {
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.8em solid #000000;
  border-right: 0.8em solid #000000;
  border-bottom: 0.8em solid #000000;
  border-left: 0.8em solid transparent;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
