.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #cfd3d6;
  background: #f8f9f9;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.icon {
  width: 24px;
  margin-bottom: -2px;
}
