.full {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px;
  border: 1px solid #e6e8ea;
  border-radius: 8px;
}

.mini {
  width: 100%;
}

.graphContainer {
  position: relative;
  margin: 0;
}

.mini .graphContainer {
  width: 100%;
  max-width: 200px;
}

.full .graphContainer {
  flex: 0 0 60%;
  width: 60%;
  padding: 20px 40px;
  max-width: 460px;
}

.circularChart {
  display: block;
  aspect-ratio: 1;
}

.circle {
  fill: none;
  /* stroke-linecap: round; */
}

.circleEnableAnimation {
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.middleContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mini .middleSubtitle {
  margin-top: 10px;
}

.legendContainer {
  flex: 1 1 auto;
  padding-left: 40px;
}

.legend {
  width: min-content;
  min-width: 400px;
  gap: 25px;
  margin: 0 auto;
}

.splitLegendWrapper {
  margin-left: 30px;
}

.legendEntry {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  padding-right: 40px;
}

.splitLegendEntry {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding-right: 30px;
}

.splitLegendEntry span {
  min-width: max-content;
}

.legendMarker {
  min-width: max-content;
  height: 24px;
  width: 24px;
  border-radius: 20px;
  margin-right: 15px;
  white-space: nowrap;
}

.legendValue {
  font-size: 18px;
  font-weight: 500;
  color: var(--candor-grey-70);
  text-align: right;
}

@media (max-width: 1350px) {
  .full {
    flex-direction: column !important;
    align-items: center !important;
  }
  .full .graphContainer {
    padding: 0px;
    margin-bottom: 40px;
  }
  .legend {
    width: 100%;
    padding: 0px;
  }
}

@media (max-width: 600px) {
  .full {
    padding: 20px;
  }

  .circularChart {
    width: 70vw;
  }

  .full .graphContainer {
    padding: 0px;
    width: auto;
    margin-top: 30px;
  }

  .legendContainer {
    width: 100%;
  }

  .legend {
    width: 100%;
    padding: 0px;
    gap: 15px;
    min-width: unset;
  }

  .graphContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .legendContainer {
    padding-left: 0;
  }

  .legendMarker {
    margin-right: 1.125rem;
    min-width: max-content;
    align-items: center;
  }
  .legendEntry {
    font-size: 1rem;
    line-height: 1.3125rem;
  }
  .legendValue {
    font-size: 1rem;
    line-height: 1.3125rem;
    color: var(--candor-grey-70);
  }

  .splitLegendWrapper {
    margin-left: 10px;
  }

  .splitLegendEntry span {
    min-width: initial;
    font-size: 12px;
  }

  .splitLegendValue {
    display: none !important;
  }
}
