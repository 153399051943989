.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.1s;
  visibility: hidden;
  cursor: pointer;
}

.wrapper.visible {
  opacity: 1;
  visibility: visible;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: default;
  visibility: hidden;
  overflow: auto;
  width: 600px;
  height: 100%;
}

.wrapper.visible .container {
  visibility: visible;
}
.title {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  justify-content: space-between;
  padding: 0 40px;
  border-bottom: 1px solid #cfd3d6;
  height: 70px;
}

.closeIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.closeIcon:hover {
  fill: black !important;
}

.bottomCtaWrapper {
  margin-top: auto;
}

.bottomCta {
  border-top: 1px solid var(--Grey-1, #e6e8ea);
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cancel {
  cursor: pointer;
  margin-right: auto;
  color: var(--Grey-5, #6b7075);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.cancel:hover {
  text-decoration: underline;
}
