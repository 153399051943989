.container {
  position: fixed;
  z-index: 99999999;
  bottom: 0;
  border: 0;
  right: 0;
  padding: 48px;
}

.button {
  color: #141719;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: none;
}

.button svg {
  width: 14px;
  margin-left: 8px;
  margin-bottom: -3px;
}

.button svg path {
  fill: #1c1f23;
}

.expandedContainer {
  width: 376px;
  max-width: 376px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  box-shadow:
    0px 2px 10px 0px rgba(0, 0, 0, 0.1),
    0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  color: #141719;
}

.initialGreenTopBar {
  height: 8px;
  background: #bde02d;
}

.widgetContent {
  padding: 35px 20px;
}

.expandedTitle {
  display: flex;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 35px;
}

.expandedAdditionalTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 12px;
}

.expandedSubtitle {
  color: #41464c;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.widgetCaret {
  padding: 7px;
  width: 25px;
  margin-bottom: -3px;
  margin-left: auto;
  cursor: pointer;
}

.widgetCaret:hover {
  border-radius: 4px;
  background: #f8f9f9;
}

.expandedButtonContainer {
  border-top: 1px solid #e6e8ea;
  padding: 24px 20px;
}

.expandedButton {
  text-transform: none;
  font-weight: 500;
  width: 100%;
}

.widgetCompleteBgIcon {
  margin-top: 32px;
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.listItemIndex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #def581;
  color: #141719;
  font-size: 12.8px;
  font-weight: 400;
  line-height: 14px;
  border-radius: 50%;
  margin-right: 16px;
}

.listItemCompleted {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #ebfaac;
  border-radius: 50%;
  margin-right: 16px;
}

.listItemCompleted svg {
  width: 16px;
}

.listItemNotStarted {
  background-color: #e6e8ea;
}

.listItemTitle {
  color: #141719;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.listTitle {
  font-size: 16px;
  line-height: 24px;
}
