.container {
  height: auto;
  min-height: 100vh;
}

.content {
  display: flex;
  margin-left: 200px;
  align-items: stretch;
}

.leftContainer {
  flex: 1 1 auto;
  width: auto;
  padding-bottom: 100px;
  background-color: #ffffff;
  min-height: 100vh;
}

.leftContainerSolo {
  flex: 1 1 auto;
  padding-bottom: 100px;
  background: #f8f9f9;
  min-height: 100vh;
  width: 100%;
  height: fit-content;
}

.containerNoNav {
  background-color: var(--candor-background-grey);
}

.leftHeaderContainer {
  padding: 35px 30px;
  max-width: 1400px;
}

.hideNavContainer {
  height: 180px;
}

.hideNav {
  display: none !important;
}

.title {
  color: var(--Primary-Grey-9, #141719);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightContainer {
  flex: 0 0 380px;
  width: 380px;
  padding: 30px 30px 60px 40px;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  overflow: hidden;
  background-color: #f9f9f9;
}

.rightTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #aeaeae;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.leftContent {
  padding: 0 30px;
  max-width: 1400px;
}

.subheadStatus {
  font-size: 20px;
  font-weight: 500;
  -webkit-text-fill-color: transparent;
  display: inline-flex;
  align-items: center;
  gap: 30px;
}

.subheadStatusSuccess {
  background: linear-gradient(to right, #7af2d0, #87f296 25%, #c6ee52 50%, #f2d84d);
  background-clip: text;
}

.subheadStatusWarning {
  background: linear-gradient(to right, #eddd4d, #ffa34f);
  background-clip: text;
}

.subheadStatusError {
  background: linear-gradient(to right, #ff6b6b, #ffa34f);
  background-clip: text;
}

.subheadStatusLoading {
  height: 20px;
}

.subheadIcon {
  height: 24px;
  width: 24px;
  margin-top: 2px;
}

.subhead {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: black;
  margin-top: 5px;
}

.subheadContainer {
  margin-top: 25px;
  gap: 20px;
}

.navBar {
  width: 200px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--candor-grey-30);
  align-items: center;
  gap: 40px;
  background-color: white;
  position: fixed;
  height: 100%;
}

.logo {
  max-width: 80px;
  margin-top: 30px;
  margin-bottom: 120px;
  cursor: pointer;
}

.navItem {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: none;
  position: relative;
  color: #aeaeae;
  padding-left: 18px;
  transition: all 0.3s ease;
}

.navItem svg {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  display: block;
  margin: 0 auto;
}

.navItem > span {
  flex: 1 1 auto;
  font-style: normal;
  font-weight: bold;
  line-height: 140%;
  padding-left: 15px;
}

.navItem.disabled {
  cursor: not-allowed;
  color: #c3c3c3;
}

.navItem.active {
  color: black;
}

.navItem.active::after {
  content: "";
  position: absolute;
  left: -1px;
  height: 24px;
  width: 4px;
  border-right: 4px solid black;
  border-radius: 5px;
  margin-right: 1px;
}

.navItem:last-of-type {
  margin-bottom: 200px;
  margin-top: auto;
}

.navItem:hover {
  box-shadow: none;
}

.navItem:not(.disabled):hover {
  color: black;
}

.mobileNav {
  display: none;
}

.navItemTitleContainer {
  flex: 0 0 auto;
}

.hideTooltip {
  display: none;
}

/* Collapse nav bar on tablet */
@media (max-width: 1200px) {
  .content {
    margin-left: 80px;
  }
}

/* Mobile */
@media (max-width: 600px) {
  .navBar {
    display: none;
  }
  .mobileNav {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    z-index: 999;
  }
  .mobileLogo {
    height: 20px;
  }
  .burger {
    height: 20px;
  }
  .mobileLogo {
    fill: white;
  }
  .burgerNav {
    width: 50vw;
    right: 0;
    margin: 20px 15px;
    z-index: 999;
    padding: 25px;
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: #ffffff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    font-weight: bold;
    transition: all 0.3s ease-in;
  }
  .container > :global(.display-enter) {
    opacity: 0;
  }

  .container > :global(.display-enter-active) {
    opacity: 1;
    transition: all 0.3s ease;
  }

  .container > :global(.display-exit-active) {
    opacity: 0;
    transition: all 0.3s ease;
  }
  .navItem {
    padding: 10px 0px;
    color: #c4c4c4;
  }
  .navItem:last-of-type {
    margin: 0px;
  }
  .navItem > span {
    display: block;
    font-size: 16px;
    padding-left: 10px;
  }
  .navItem svg {
    margin: 0px;
    height: 16px;
    width: 16px;
  }
  .navItem.active::after {
    display: none;
  }
  .selectedNavTitle {
    color: #000000;
  }
  .leftContainer {
    flex: 1;
    width: 100%;
    min-height: auto;
    padding-bottom: 0px;
  }
  .rightContainer {
    width: 100%;
    flex: 0 0 100%;
    max-width: initial;
    background-color: #ffffff;
    overflow: visible;
    padding: 35px 20px;
  }
  .content {
    margin-left: 0px;
    flex-direction: column;
  }
  .leftHeaderContainer {
    background-position-x: 50vw;
    padding: 100px 20px 40px;
  }

  .hideNavContainer {
    padding: 80px 0px 0px;
  }

  .leftContent {
    padding: 80px 20px 0px;
  }

  .subheadContainer {
    margin-top: 15px;
    gap: 5px;
  }
  .subheadIcon {
    height: 22px;
    width: 22px;
    margin-top: 2px;
  }
  .subheadStatus {
    font-size: 16px;
  }
  .subhead {
    font-size: 12px;
  }
  .leftContainerSolo > .leftContent {
    margin-top: -100px;
  }
  .leftContainerSolo {
    padding-bottom: 100px;
  }
}
