.legendBar {
  height: 10px;
  width: 100%;
  display: flex;
  margin-bottom: 60px;
}

.legendBar > div {
  height: 100%;
}

.bar:not(:first-of-type):not(:last-of-type) {
  border-right: 1px dashed #0f0f0f;
}

.noShares {
  background: var(--candor-background-grey);
  width: 100%;
}

@media (max-width: 600px) {
  .bar {
    min-width: 30% !important;
  }
}
