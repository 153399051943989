.container {
  border: 1px solid #aeaeae;
  border-radius: 20px;

  box-sizing: border-box;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  padding: 0px;
  position: relative;
  overflow: hidden;
}

.innerContainer {
  background-color: white;
  height: 100%;
  padding: 30px;
  display: flex;
  gap: 50px;
}

.container.mini .innerContainer {
  padding: 20px 25px 10px;
}

.container.containerAccentWhite,
.container.containerAccent {
  padding: 5px;
  background: linear-gradient(to right, #76ec86, #eddd4d);
  border: 0px;
}

.containerAccentWhite .title {
  color: black;
}

.innerContainer.containerAccent {
  border-radius: 15px;
  background: linear-gradient(0deg, #000000, #000000), linear-gradient(206.71deg, #1e3339 -4.21%, #0f181a 87.63%),
    linear-gradient(159.73deg, #e7c290 33.14%, #c2a888 139.01%, #ab875c 170.66%);
}

.innerContainer.containerAccentWhite {
  border-radius: 15px;
}

.container.containerAccentWarning,
.container.containerAccentError {
  border: 0px;
}

.innerContainer.containerAccentError {
  border: 4px solid #ff6b6b;
  border-radius: 20px;
  background: linear-gradient(0deg, #000000, #000000), linear-gradient(206.71deg, #1e3339 -4.21%, #0f181a 87.63%),
    linear-gradient(159.73deg, #e7c290 33.14%, #c2a888 139.01%, #ab875c 170.66%);
}

.innerContainer.containerAccentWarning {
  border: 4px solid #ffa34f;
  border-radius: 20px;
  background: linear-gradient(0deg, #000000, #000000), linear-gradient(206.71deg, #1e3339 -4.21%, #0f181a 87.63%),
    linear-gradient(159.73deg, #e7c290 33.14%, #c2a888 139.01%, #ab875c 170.66%);
}

.containerGreyActive {
  cursor: pointer;
}

.innerContainer.containerGreyActive,
.innerContainer.containerAccentDisabled {
  background-color: #f0f0f0;
}

.containerLightGrey {
  background: #f0f0f0;
}

.containerDarkGrey {
  background: #222222;
  backdrop-filter: blur(50px);
  color: white;
}

.container.containerLoading {
  padding: 5px;
  animation: shimmer 6s infinite linear;
  background: linear-gradient(to right, #e2e2e2 4%, rgba(0, 0, 0, 0.2) 25%, #e2e2e2 36%);
  transition: all 0.2s ease;
}

.innerContainer.innerLoading {
  visibility: hidden;
}

.innerContainer.containerGreyActive:hover {
  background-color: var(--candor-neon-green);
}

.innerContainer.containerOrangeActive {
  padding: 20px 30px;
  background-color: #f0f0f0;
}

.innerContainer.containerOrangeActive:hover {
  background-color: #e7d7c3;
}

.container.containerLime {
  background: var(--candor-neon-green);
  border: 1px solid #000000;
  border-radius: 5px;
}

.innerContainer.containerLime {
  background: var(--candor-neon-green);
  border-radius: 5px;
}

.containerClickable {
  cursor: pointer;
  transition: all 0.2s;
}

.containerClickable:hover {
  border-color: #000000;
}

.containerClickable.containerAccentWhite:hover {
  border: 0px;
  background: linear-gradient(180deg, #76ec86, #eddd4d);
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.actionTitle {
  color: #a8a8a8;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
}

.container.mini,
.mini .innerContainer {
  border-radius: 15px;
}

.container.mini .actionTitle {
  font-size: 0.8125rem;
}

.actionTitle > svg {
  height: 16px;
  width: 18px;
  margin-right: 10px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.titleAccent {
  color: white;
}

.titleDisabled {
  color: #808080;
}

.CTAContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 15px;
}

.topContent {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.topRightInfo,
.bottomRightInfo {
  font-style: italic;
  font-weight: normal;
  line-height: 140%;
  color: #c4c4c4;
  display: flex;
}

.topRightInfo {
  justify-content: flex-end;
  font-size: 16px;
  margin-left: auto;
}

.bottomRightInfo {
  justify-content: flex-end;
  font-size: 0.875rem;
  margin-top: 10px;
  margin-bottom: -20px;
}

.family {
  font-style: italic;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
  color: #c4c4c4;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 23px;
}

.optionsDotsContainer {
  position: absolute;
  right: 30px;
  cursor: pointer;
  width: 30px;
  display: flex;
  justify-content: center;
}

.optionsDotsContainer > svg {
  width: 6px;
  height: 26px;
}

.optionsContainer {
  position: absolute;
  right: -160px;
  top: -20px;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
}

.optionContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 1rem;
}

.optionContainer:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.optionContainer:first-of-type {
  padding-top: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.optionContainer:last-of-type {
  padding-bottom: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.optionContainer > svg {
  height: 18px;
  width: 18px;
  fill: white;
}

.content {
  color: #999;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.rightArrowIcon {
  top: 50%;
  right: 85px;
}

.icon > svg,
.icon > svg {
  width: 45px;
  height: 45px;
}

.icon {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .innerContainer {
    gap: 20px;
  }
  .innerContainer,
  .innerContainer.containerAccentWhite,
  .innerContainer.containerAccent {
    border-radius: 15px;
  }

  .innerContainer {
    padding: 18px 20px 20px;
    border-radius: 20px;
  }
  .container {
    border-radius: 20px;
    max-width: 100%;
  }

  .title {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .actionTitle {
    font-size: 12px;
    line-height: 16px;
  }
  .topRightInfo {
    font-size: 12px;
  }
}
