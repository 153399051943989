.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  height: 40px;
  width: 269px;
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 1);
  margin-left: 17px;
}

.container input {
  position: relative;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  margin-top: 12px;
}

.container svg {
  width: 24px;
  height: 24px;
}
